<template>
  <el-container style="height: 100%">
    <el-main class="main" v-if="showflag" style="height: 100%;">
      <div class="message-record">
        <div style="cursor: pointer;height: 15px;text-align: center" @mouseover="sessionShow = true" @mouseout="sessionShow = false">
          <transition name="fade">
            <div v-if="sessionShow" style="font-size: 15px;color: #999999">{{this.userId.slice(this.userId.indexOf('-')+1)}}</div>
          </transition>

        </div>
        <el-scrollbar ref="selfScrollbar" style="height: 100%" id="msgList">
          <div v-if="firstConnAuthList.length>0"
               style="font-size: 14px;color: #c2baca;padding: 10px;">
            <template v-for="autoreply in firstConnAuthList">
              <div class="firstConnAuth">
                <div v-html="autoreply.content"></div>
              </div>
            </template>
          </div>
          <template v-for="item in messageConfig.data">
            <div :class="['message-row',{self: item.optstatus==1}]" v-if="item.optstatus==0 || item.optstatus==1 || item.optstatus==2">
              <div>
                <span><img v-if="item.headimgurl" :src="item.headimgurl" width="30" style="padding-right: 4px">{{ item.nickname }}</span>
                <span style="padding-left: 5px">{{ timeToString(item.sendTime) }}</span>
              </div>
              <div class="message-content">
                <div v-html="item.content"></div>
              </div>
            </div>
            <div v-if="item.optstatus==2"
                 style="font-size: 14px;color: #c2baca;">
              <div>请点击下面按钮进行操作</div>
              <div style="margin: 10px 0">
                <el-row>
                  <el-button :style="{backgroundColor: configset.notonlinebtncolor,color:'white'}"
                             size="mini" @click="handleCallBackCreate">给您回电
                  </el-button>
                  <el-button :style="{backgroundColor: configset.notonlinebtncolor,color:'white'}"
                             size="mini" @click="handleCreate">在线留言
                  </el-button>
                </el-row>
              </div>
            </div>
            <div v-if="item.optstatus==3"
                 style="font-size: 14px;color: #c2baca;padding: 10px;">
              <div><i class="el-icon-info"></i>{{configset.notonlinetext}}</div>
              <div style="margin-top: 10px">请点击下面按钮进行操作</div>
              <div style="margin: 10px 0px">
                <el-row>
                  <el-button :style="{backgroundColor: configset.notonlinebtncolor,color:'white'}"
                             size="mini" @click="handleCallBackCreate">给您回电
                  </el-button>
                  <el-button :style="{backgroundColor: configset.notonlinebtncolor,color:'white'}"
                             size="mini" @click="handleCreate">在线留言
                  </el-button>
                  <el-button :style="{backgroundColor: configset.notonlinebtncolor,color:'white'}"
                             size="mini" @click="changeRobot">智能客服
                  </el-button>
                </el-row>
              </div>
            </div>
            <div v-if="item.optstatus==4"
                 style="font-size: 14px;color: #c2baca;padding:10px;">
              <div><i class="el-icon-info"></i>
                {{configset.autoofftext}}
              </div>
            </div>
            <div v-if="item.optstatus==5" style="font-size: 14px;color: #c2baca;padding: 10px;">
              <div><i class="el-icon-info"></i>{{ timeToString(item.sendTime) }} 人工客服在线，是否需要连接人工客服？</div>
              <div style="margin: 10px 0px">
                <el-row>
                  <el-button :style="{backgroundColor: configset.notonlinebtncolor,color:'white'}"
                             size="mini" @click="changeCs">人工客服
                  </el-button>
                </el-row>
              </div>
            </div>
          </template>
        </el-scrollbar>
      </div>
      <div class="links" style="min-height: 32px;">
        <template v-for="item in quickLinks">
          <div class="link" @click="clicklink(item.keyword,item.content)"><a>{{item.keyword}}</a>
          </div>
        </template>
      </div>
      <editor v-if="keyboardtype==2 || keyboardtype==1" @send="webSocketConfig.onSend"
              v-model="content"
              :csid="csid"
              :projid="projid"
              :btncolor="configset.notonlinebtncolor"
              placeholder="按 Enter 发送"
              @toggleSound="onToogleSound"
      ></editor>
      <div class="notconn" v-if="keyboardtype==3">
        <div>当前对话已结束，您可以 <span style="color: #00ccff"><a @click="bigetNewTalk">开始新对话</a></span>
        </div>
      </div>
      <div class="notconn" v-if="keyboardtype==1">
<!--        <div>客服不在线，您可以 <span style="color: #00ccff"><a @click="changeRobot">开启智能客服</a></span></div>-->
      </div>
      <div class="bottom-title">
        <span><el-image style="width: 16px;vertical-align: text-top;margin-right: 4px;" src="/images/icon-r.png"></el-image></span>
        <span><a href="https://www.aiocs.cn" target="_blank">默蓝客服系统由{{ $store.state.setting.technicalSupport }}提供技术支持</a></span>
      </div>
    </el-main>

    <el-main class="main" v-if="smallshowflag">
      <div></div>
    </el-main>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="300px">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="10px"
               style="">
        <el-form-item prop="content">
          <el-input v-model="temp.content" type="textarea" placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"/>
        </el-form-item>
        <el-form-item prop="username">
          <el-input v-model="temp.username" type="text" placeholder="联系人（必填）"/>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="temp.mobile" type="text" placeholder="电话（选填）"/>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="temp.email" type="text" placeholder="邮箱/QQ号码/微信号（必填）"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--评价弹框-->
    <el-dialog title="" :visible.sync="dialogFormEvaluateVisible" width="300px">
      <el-form ref="dataForm" :model="evaluate" label-position="left" label-width="10px"
               style="">
        <div style="text-align: center;margin:10px 0px;font-size: 18px;color: #1f1f1f">您对本次服务满意吗？
        </div>
        <el-form-item style="text-align: center;margin: 30px 0px 20px !important;">
          <el-rate
            v-model="evaluate.score"
            :colors="colors"
            :texts=this.texts
            show-text>
          </el-rate>
        </el-form-item>
        <div style="text-align: center;margin:10px 0px;font-size: 14px;color: #f68f48">请选择评价</div>
        <el-form-item>
          <el-input size="small" v-model="evaluate.content" :rows="3" type="textarea"
                    placeholder="您觉得我们还有哪些地方需要改进呢？"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button size="small"
                   :style="{backgroundColor: configset.notonlinebtncolor,width:'80%',color:'white'}"
                   @click="insertEvaluate()">评价
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="textCallBackMap[dialogCallBackStatus]"
               :visible.sync="dialogCallBackFormVisible" width="300px">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="10px"
               style="">
        <div style="padding: 0px 20px 20px;color: #bfbbbb;">请输入您的电话号码，稍后我们给您回电</div>
        <el-form-item prop="mobile">
          <el-input v-model="temp.mobile" type="text" placeholder="请输入电话"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCallBackFormVisible = false">
          取消
        </el-button>
        <el-button type="primary"
                   @click="dialogCallBackStatus==='create'?createCallBackData():updateCallBackData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="imgsVisible" width="80%">
      <div style="display: flex;justify-content: center;">
        <el-image :src="imgs" fit="scale-down" lazy style="margin: 10px auto;max-width: 90%">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>

  import editor from "@PAGE/common/ChatArea";
  import {addLeaveMessage} from "@/apis/robot";
  import {
    getOnLineService,
    sendOneWebSocket,
    getConfigset,
    getUserInfo,
    decryptTokenkey,
    addClientEvaluate,
    getAutoreply,
    saveRobotMsgReload
  } from "@/apis/webstocket";
  import {getsomequicklinks} from "@/apis/quick-links";
  import {getAutoReply} from "@/apis/robot";
  import config from "@/resources/js/config";
  import asyncValidator from "@/resources/js/async-validator";

  export default {
    name: "index",
    components: {
      Editor: editor
    }, props: {
      tokenkey: {
        type: String,
        default: '',
      }, customerId: {
        type: String,
        default: '',
      }
    },
    data() {
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
        // 当数据为空时，不进行校验
        if (!value) {
          return callback()
        }
        setTimeout(() => {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确的手机号码'))
          }
        }, 100)
      }
      return {
        texts: ['差', '较差', '一般', '满意', '非常满意'],
        windowTitle: '默蓝客服',
        isPlaySound: true,
        nowTime: '',
        imgs: "",
        imgsVisible: false,
        csid: 0,
        sessionShow:false,
        robotflag: false,//机器人模式
        cs_online_flag: false,//客服在线标志
        keyboardtype: 2,   //键盘类型，1 客服不在线 2 键盘输入 3 结束对话
        sendmsgtime: 0,//上一次发消息时间
        showflag: true,
        smallshowflag: false,
        userId: '',
        clientid: '',
        projid: 0,
        content: null,
        connect: {},
        webSocket: null,
        quickLinks: [],
        firstConnAuthList: [],
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
        cserverInfo: {
          realname: "智能客服",
          mobile: "020-38888813-817",
          qq: "1571071415",
          projname: "默蓝客服"
        },
        temp: {
          projid: '',
          mobile: '',
          email: '',
          username: '',
          qq: '',
          content: ''
        }, configset: {
          framecolor: null,
          notonlinetext: null,
          notonlinebtncolor: null,
          autoofftext: null,
          autoofftime: null
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          create: '添加留言'
        }, dialogCallBackFormVisible: false,
        dialogCallBackStatus: '',
        textCallBackMap: {
          create: '回电信息'
        }, rules: {
          mobile: [{required: false, validator: checkPhone, trigger: 'blur'}],
          email: [{required: true, message: '请输入邮箱/QQ号码/微信号', trigger: ['change', 'blur']}],
          content: [{required: true, message: '请输入留言内容', trigger: ['change', 'blur']}],
          username: [{required: true, message: '请输入姓名', trigger: ['change', 'blur']}]
        }, evaluate: {
          content: '',
          score: 5,
          projid: 0,
          csid: 0,
        },
        messageConfig: {
          data: [],
          loadData: () => {
          },
        },
        webSocketConfig: {
          webSocket: null,
          open: () => {
          },
          onSend: (msg) => {//发送消息
            let message = "";
            message = {
              optstatus: 1,
              content: msg,
              connid: this.connect.id,
              nickname: "我",
              serviceflag: 0,
              msgtype: 1,
              clientid: this.userId,
              projid: this.projid,
              toUserId: this.connect.csidname,
              sendTime: new Date().getTime(),
            };
            console.log(message)
            this.messageConfig.data.push(message);
            if (!this.cs_online_flag && !this.robotflag) {
              let message = {
                optstatus: 3,
              };
              console.log(message)
              this.messageConfig.data.push(message);
              //保存机器人聊天咨询内容
              let data = {
                "userId": this.userId,
                "connid": this.connect.id,
                "projid": this.projid,
                "msgtype": 1,
                "content": msg,
                "showflag": 0
              }
              saveRobotMsgReload(data);
              this.keyboardtype = 1;
              return;
            }
            this.sendmsgtime = 1;
            this.content = null;
            if (this.robotflag) {//机器人模式
              this.content = '';
              //保存机器人聊天咨询内容
              let data = {
                "projid": this.projid,
                "connid": this.connect.id,
                "msgtype": 1,
                "content": msg
              }
              saveRobotMsgReload(data);
              getAutoReply(data).then(res => {
                message = {
                  optstatus: 2,
                  userId: this.userId,
                  content: res.retdata.replaycontent,
                  nickname: '智能客服',
                  connid: this.connect.id,
                  serviceflag: 1,
                  projid: this.projid,
                  toUserId: this.userId,
                  sendTime: new Date().getTime(),
                };
                this.messageConfig.data.push(message);
                //保存自动回复内容
                data = {
                  "projid": this.projid,
                  "msgtype": 2,
                  "replaycontent": res.retdata.replaycontent
                }
                console.log("---------->",data)
                saveRobotMsgReload(data);
              })
            } else {
              sendOneWebSocket(message);
            }
          }
        },
        lockReconnect: false,
        timeout: 30 * 1000,//58秒一次心跳
        timeoutObj: null,//心跳心跳倒计时
        serverTimeoutObj: null,//心跳倒计时
        timeoutnum: null,//断开 重连倒计时
        dialogFormEvaluateVisible: false,
      }
    },
    mounted() {
      this.decryptTokenkey();
    }, updated() {
      this.selfScrollDown();
    }, beforeDestroy() {
      this.onbeforeunload()
    }, created() {
      window.imgView = this.imgView;
      window.clickSend = this.clickSend
    }, methods: {
      getAutoreply() {//获取自动发送内容
        var date = {
          projid: this.projid
        }
        getAutoreply(date).then(res => {
          this.firstConnAuthList = res.retdata;
        })
      }, getSessionId() {//获取用户的SessionId
        var date = {
          projid: this.projid
        }
        getOnLineService(date).then(res => {
          //历史消息
          let this_ = this;
          this_.messageConfig.data = [];
          console.log("res",res)
          res.retdata.consultingList.forEach(function (item, index) {
            let optstatus = 0;
            let nickname = this_.cserverInfo.realname;
            if (item.msgflag == 1) {
              optstatus = 1;
              nickname = '我';
            }
            let message = {
              optstatus: optstatus,
              content: item.content,
              nickname: nickname,
              sendTime: item.createtime,
            };
            this_.messageConfig.data.push(message);
          })
          this.userId = res.retdata.clientname
          this.clientid = res.retdata.clientid
          if (res.retdata.csid == null) {//客服不在线
            console.log("客服不在线---->",res.retdata)
            this.cs_online_flag = false;
            this.keyboardtype = 2;
            let data = {
              "projid": this_.projid,
              "msgtype": 1,
              "content": "用户打开网站，匿名访问",
              "showflag": 0,
              "csid": res.retdata.csid,
              "isanonymous":1,
              "connid": res.retdata.id,
            }
            this.connect.id = res.retdata.id
            saveRobotMsgReload(data);
          } else {//客服在线，并且自动连接
            this.init();
            this.keyboardtype = 2;
            this.cs_online_flag = true;
            this.robotflag = false
            this.cserverInfo.realname = res.retdata.realname;
            this.cserverInfo.projname = res.retdata.projname;
            this.cserverInfo.mobile = res.retdata.mobile;
            this.cserverInfo.nickname = res.retdata.nickname;
            this.cserverInfo.headimgurl = res.retdata.headimgurl;
            this.cserverInfo.qq = res.retdata.qq;
            this.userId = res.retdata.clientname;
            this.connect = res.retdata;
            this.csid = res.retdata.csid;
            if (res.retdata.authReply != null && res.retdata.authReply.length > 0) {
              res.retdata.authReply.forEach(item => {
                let message = {
                  optstatus: 0,
                  userId: this.userId,
                  content: item.content,
                  nickname: this.cserverInfo.nickname,
                  headimgurl: this.cserverInfo.headimgurl,
                  connid: this.connect.id,
                  serviceflag: 0,
                  toUserId: this.userId,
                  sendTime: new Date().getTime(),
                };
                this.messageConfig.data.push(message);
                //保存连接客服时自动发出的消息
                let data = {
                  "projid": this_.projid,
                  "msgtype": 2,
                  "replaycontent": item.content,
                  "showflag": 0,
                  "csid": this_.csid,
                  "connid": this_.connect.id,
                }
                saveRobotMsgReload(data);
              })
            } else {
              let message = {
                optstatus: 0,
                userId: this.userId,
                content: "您好，很高兴为您服务，有什么需要咨询的吗？",
                nickname: this.cserverInfo.nickname,
                headimgurl: this.cserverInfo.headimgurl,
                connid: this.connect.id,
                serviceflag: 0,
                toUserId: this.userId,
                sendTime: new Date().getTime(),
              };
              this.messageConfig.data.push(message);
            }
            this.$emit('getCsid', res.retdata.csid)
            this.$emit('getCserverInfo', this.cserverInfo)
            this.connect.csidname = "csService-" + res.retdata.csid;
            //保存机器人聊天咨询内容
            let data = {
              "projid": this_.projid,
              "msgtype": 1,
              "content": "用户打开网站，已连接客服编号：" + this.csid,
              "showflag": 0,
              "csid": this_.csid,
              "connid": this_.connect.id,
            }
            saveRobotMsgReload(data);
          }
          this.initWebSocket()
          //通知后台我已经连上了
          let message = {
            msgtype: 5,
            clientid: res.retdata.clientid,
            projid: this_.projid,
            touserid: this.connect.csidname,
            connid: this_.connect.id,
          };
          sendOneWebSocket(message)
        })
      },
      getsomequicklinks() {
        let data = {
          projid: this.projid
        }
        getsomequicklinks(data).then(res => {
          const {count = 0} = res;
          this.quickLinks = res.retdata;
        })
      },
      updatestatus() {
        let message = {
          connid: this.connect.id,
          msgtype: 6,
          userid: this.userId,
          projid: this.projid,
          clientid: this.clientid,
          touserid: this.connect.csidname,
        };
        sendOneWebSocket(message);
      }, changeRobot() {
        this.robotflag = true;
        this.keyboardtype = 2;
        this.cserverInfo = {
          realname: "智能客服",
          mobile: "18057109608",
          qq: "75667061",
          projname: "默蓝智能客服"
        }
        let message = {
          optstatus: 0,
          userId: this.userId,
          content: "您好，很高兴为您服务，有什么需要咨询的吗？",
          nickname: '智能客服',
          connid: this.connect.id,
          serviceflag: 0,
          toUserId: this.userId,
          sendTime: new Date().getTime(),
        };
        this.messageConfig.data.push(message);
      },
      initWebSocket() {
        if ('WebSocket' in window) {
          //console.log(this.userId)
          let appPath = window.location.origin;
          if (appPath) {
            if (appPath.indexOf("https") == -1) {
              appPath = appPath.replaceAll("http", "ws");
            } else {
              appPath = appPath.replaceAll("https", "wss");
            }
          }
          //this.websocket = new WebSocket('ws://localhost:8081/websocket/' + this.userId)
          console.log(appPath + `${config.BASE_URL}/websocket/${this.userId}`)
          this.webSocket = new WebSocket(appPath + `${config.BASE_URL}/websocket/${this.userId}`)

          // 连接错误
          this.webSocket.onerror = this.setErrorMessage
          // 连接成功
          this.webSocket.onopen = this.setOnopenMessage
          // 收到消息的回调
          this.webSocket.onmessage = this.setOnmessageMessage
          // 连接关闭的回调
          this.webSocket.onclose = this.setOncloseMessage
          // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
          window.onbeforeunload = this.onbeforeunload
        } else {
          alert('当前浏览器 Not support websocket')
        }
      },
      setErrorMessage() {
        console.log('WebSocket连接发生错误   状态码：' + this.webSocket.readyState)
      },
      setOnopenMessage() {
        console.log('WebSocket连接成功    状态码：' + this.webSocket.readyState)
        this.start();
      }, resetTemp() {
        this.temp = {
          projid: this.projid,
          mobile: '',
          email: '',
          qq: '',
          username: '',
          content: ''
        }
      },
      setOnmessageMessage(event) {
        // 根据服务器推送的消息做自己的业务处理
        let content = event.data;
        const mes = JSON.parse(content);
        console.log(mes)
        let msgtype = mes.msgtype;
        //console.log(msgtype)
        if (msgtype == 2) {
          //this.windowTitle=window.top.document.title;
          //window.top.document.title = '[您有新的消息]';
          let message = {
            optstatus: 0,
            userId: this.userId,
            content: mes.content,
            nickname: this.cserverInfo.nickname,
            headimgurl: this.cserverInfo.headimgurl,
            connid: this.connect.id,
            serviceflag: 0,
            toUserId: this.userId,
            sendTime: new Date().getTime(),
          };
          this.messageConfig.data.push(message);
          if (this.isPlaySound) {
            let audio = new Audio()
            audio.src = "/audio/msg.mp3"
            audio.play();
          }
        }
        if (msgtype == 3) {//客服迁出
          this.cs_online_flag = false;
          this.openEvaluate();
          this.keyboardtype = 1;
          let message = {
            optstatus: 3,
          };
          this.connect.id = null
          this.messageConfig.data.push(message);
        }
        if (msgtype == 4) {//客服迁入
          this.nowTime = new Date().getTime();
          if (!this.cs_online_flag) {
            let message = {
              optstatus: 5,
              sendTime: new Date().getTime(),
            };
            this.messageConfig.data.push(message);
          }
        }
        if (msgtype == 9) {//客户心跳
          //收到服务器心跳信息，心跳重置
          this.reset();
        }
      },
      setOncloseMessage() {
        console.log('WebSocket连接关闭    状态码：' + this.webSocket.readyState)
        this.$emit('getCserverInfo', {"nickname": "智能客服", "headimgurl": "/images/default.png"})
        this.updatestatus();
      },
      onbeforeunload() {
        this.closeWebSocket()
      },
      closeWebSocket() {
        clearTimeout(this.timeoutObj)
        this.webSocket.close()
      }, reconnect() {//重新连接
        var that = this;
        if (that.lockReconnect) {
          return;
        };
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum);
        that.timeoutnum = setTimeout(function () {
          //新连接
          console.log("重连中")
          that.initWebSocket();
          that.lockReconnect = false;
        }, 5000);
      },
      reset() {//重置心跳
        var that = this;
        //清除时间
        clearTimeout(that.timeoutObj);
        //重启心跳
        that.start();
      },
      start() { //开启心跳
        //console.log('开启心跳');
        var self = this;
        self.timeoutObj && clearTimeout(self.timeoutObj);
        self.timeoutObj = setTimeout(function () {
          //这里发送一个心跳，后端收到后，返回一个心跳消息
          if (self.webSocket.readyState == 1) {//如果连接正常
            let message = {
              connid: self.connect.id,
              msgtype: 9,
              clientid: self.clientid,
              projid: self.projid,
              toUserId: self.userId,
            };
            sendOneWebSocket(message).then(res => {
            })
          }
        }, self.timeout)
      },
      onopen() {
        console.log("open");
        //开启心跳
        this.start();
      }, clicklink(msg, content) {
        let message = "";
        let this_ = this;
        message = {
          optstatus: 1,
          content: msg,
          connid: this.connect.id,
          nickname: "我",
          serviceflag: 0,
          projid: this.projid,
          msgtype: 1,
          clientid: this.userId,
          toUserId: this.connect.csidname,
          sendTime: new Date().getTime(),
        };
        this_.messageConfig.data.push(message);
        if (!this_.cs_online_flag) {
          //保存机器人聊天咨询内容
          let data = {
            "projid": this_.projid,
            "msgtype": 1,
            "content": msg
          }
          saveRobotMsgReload(data);
        } else {
          sendOneWebSocket(message);
        }
        message = {
          optstatus: 0,
          userId: this.userId,
          content: content,
          nickname: this.cserverInfo.nickname,
          headimgurl: this.cserverInfo.headimgurl,
          connid: this.connect.id,
          serviceflag: 1,
          projid: this.projid,
          msgtype: 2,
          clientid: this.userId,
          toUserId: this.userId,
          sendTime: new Date().getTime(),
        };
        if (!this_.cs_online_flag) {
          setTimeout(function () {
            //保存机器人聊天回复内容
            let data = {
              "projid": this_.projid,
              "msgtype": 2,
              "replaycontent": content
            }
            saveRobotMsgReload(data);
            this_.messageConfig.data.push(message);
          }, 1000)
        } else {
          setTimeout(function () {
            sendOneWebSocket(message);
          }, 500)
        }
      }, init() {//刷新上一次发送的时间
        let this_ = this;
        var interval = setInterval(() => {
          this.sendmsgtime++;
          let sendmsgtime = this_.sendmsgtime;
          if (sendmsgtime == this_.configset.autoofftime) {
            if (this.cs_online_flag) {
              this.openEvaluate();
              this.cs_online_flag = false;
              this.keyboardtype = 3;
              clearInterval(interval);
              this.sendmsgtime = 0;
              let message = {
                optstatus: 4,
              };
              this_.messageConfig.data.push(message);
              this_.closeWebSocket();
            } else {
              clearInterval(interval);
              this.sendmsgtime = 0;
            }
          }
        }, 1000);
      }, handleCreate() {
        this.resetTemp()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      }, createData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            addLeaveMessage(this.temp).then(res => {
              this.$message.success({message: res.retDesc, offset: 50});
              this.dialogFormVisible = false;
            });
          }
        })
      }, handleCallBackCreate() {
        this.resetTemp()
        this.dialogCallBackStatus = 'create'
        this.dialogCallBackFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      }, createCallBackData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.content = "给我回电";
            addLeaveMessage(this.temp).then(res => {
              this.$message.success({message: "感谢您的信任，我们将尽快给您回电", offset: 50});
              this.dialogCallBackFormVisible = false;
            });
          }
        })
      }, selfScrollDown() {
        this.$refs['selfScrollbar'].wrap.scrollTop = this.$refs['selfScrollbar'].wrap.scrollHeight;
      }, imgView(src) {
        this.imgsVisible = true;
        this.imgs = src;
      }, onToogleSound(state) {
        this.isPlaySound = state;
      }, getConfigset() {
        let data = {
          projid: this.temp.projid
        }
        getConfigset(data).then(res => {
          console.log("****",res)
          this.configset = res.retdata;
          this.$emit('configset', res.retdata)
          if (this.configset.aboutCarousel) {
            this.configset.aboutCarousels = this.configset.aboutCarousel.split(",");
          }
        })
      }, changeCs() {
        this.getSessionId();
      }, bigetNewTalk() {//会话结束，开启新会话
        this.getSessionId();
      }, decryptTokenkey() { //地址栏参数解码
        let data = {
          tokenkey: this.tokenkey
        }
        decryptTokenkey(data).then(res => {
          this.temp.projid = res.retdata.projid;
          this.projid = res.retdata.projid;
          //console.log(this.projid)
          this.getAutoreply();
          this.getSessionId();
          this.webSocketConfig.open();
          this.getsomequicklinks();
          this.getConfigset();
          this.getUserInfo();
        })
      }, getUserInfo() { //获取客户信息
        // customerId 解密
        if (this.customerId != '') {
          let data = {
            s: this.customerId,
            projid: this.projid,
          }
          getUserInfo(data);
        }
      }, clickSend(msg) {
        let message = "";
        let this_ = this;
        message = {
          optstatus: 1,
          content: msg,
          connid: this.connect.id,
          nickname: "我",
          serviceflag: 0,
          projid: this.projid,
          msgtype: 1,
          clientid: this.userId,
          toUserId: this.connect.csidname,
          sendTime: new Date().getTime(),
        };
        this.messageConfig.data.push(message);
        if (this.robotflag || this.connect.id == undefined) {//机器人模式
          //保存机器人聊天咨询内容
          let data = {
            "projid": this_.projid,
            "msgtype": 1,
            "content": msg
          }
          saveRobotMsgReload(data);
        } else {
          sendOneWebSocket(message);
        }
        let data = {
          content: msg
        }
        getAutoReply(data).then(res => {
          message = {
            optstatus: 0,
            userId: this.userId,
            content: res.retdata.replaycontent,
            nickname: this.cserverInfo.nickname,
            headimgurl: this.cserverInfo.headimgurl,
            connid: this.connect.id,
            serviceflag: 1,
            projid: this.projid,
            msgtype: 2,
            clientid: this.userId,
            toUserId: this.userId,
            sendTime: new Date().getTime(),
          };
          if (this_.robotflag || this_.connect.id == undefined) {//机器人模式
            //保存机器人聊天咨询内容
            setTimeout(function () {
              //保存机器人聊天回复内容
              let data = {
                "projid": this_.projid,
                "msgtype": 2,
                "replaycontent": res.retdata.replaycontent
              }
              saveRobotMsgReload(data);
              this_.messageConfig.data.push(message);
            }, 500)
          } else {
            setTimeout(function () {
              sendOneWebSocket(message);
            }, 500)
          }
        })
      }, insertEvaluate() {
        this.evaluate.projid = this.projid,
          this.evaluate.csid = this.csid
        addClientEvaluate(this.evaluate).then(res => {
          this.$message.success({message: res.retDesc, offset: 50});
        })
        this.dialogFormEvaluateVisible = false;
      }, openEvaluate() {
        this.dialogFormEvaluateVisible = true;
      }
    },
  }
</script>

<style scoped lang="scss">

  .iconft {
    font-size: 24px;
    padding-right: 10px;
  }

  .el-header, .el-footer {
    line-height: 60px;
  }

  .el-aside {
    background-color: #D3DCE6;
  }

  .el-container {
    height: 100%;
  }

  .right-aside {
    .title {
      line-height: 50px;
      text-align: center;
      border-bottom: 1px #eee solid;
    }
  }

  .el-main.main ::v-deep {
    padding: 0;
    height: 100%;
    $editor-height: 230px;

    .message-record {
      box-sizing: border-box;
      background-color: white;
      height: calc(100% - #{$editor-height});
      padding: 0 10px 10px 10px;

      .message-row {
        box-sizing: border-box;
        margin-bottom: 15px;

        &.self {
          text-align: right;
          margin-right: 20px;

          .message-content {
            &:before {
              left: 100%;
              border: 5px solid transparent;
              border-left-color: #F8f8f8;
            }
          }
        }

        .message-content {
          box-sizing: border-box;
          padding: 0 10px;
          display: inline-block;
          line-height: 2.5;
          border: 1px #eee solid;
          background-color: #F8f8f8;
          border-radius: 3px;
          position: relative;
          margin-top: 5px;

          &:before {
            box-sizing: border-box;
            content: '';
            top: 10px;
            position: absolute;
            right: 100%;
            border: 6px solid transparent;
            border-right-color: #F8f8f8;
          }
        }
      }
    }

    .el-tiptap-editor {
      height: $editor-height;
      border-radius: unset;

      .ProseMirror {
        min-height: 100%;
      }

      .el-tiptap-editor__menu-bar:before {
        margin: 0;
      }

      & > .el-tiptap-editor__content {
        padding: 10px;
        flex: 1;
      }

      .border-top-radius {
        border-radius: unset;
      }
    }
  }

  .links {
    display: flex;
    background-color: white;
    flex-wrap: wrap;
  }

  .link {
    padding: 2px 10px;
    border: 1px solid;
    border-radius: 24px;
    margin: 2px 2px 5px 5px;
    color: #969798;
  }

  .editor {
    height: 185px !important;
  }

  .notconn {
    height: 185px;
    background-color: white;
    border-top: 1px solid #e0dede;
    line-height: 200px;
    text-align: center;
    font-size: 16px;
  }

</style>
<style>
  .el-scrollbar__wrap {
    overflow-x: hidden;
    height: 100%;
  }

  .el-form-item {
    margin-bottom: 5px !important;
  }

  .el-dialog__body {
    padding: 10px 10px;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }

  .el-form-item__error {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: relative;
    margin-top: 2px !important;
  }

  .el-message--success {
    top: 100px !important;
    left: 50% !important;
  }

  .el-message {
    min-width: 250px !important;
    top: 50px !important;
  }

  .el-rate__icon {
    font-size: 30px;
    margin-right: 6px;
    color: #C0C4CC;
    transition: .3s;
  }

  .firstConnAuth {
    text-align: left;
    word-break: break-all;
    background-color: white;
    border: 1px solid #cccccc;
    border-radius: 5px;
    clear: both;
    color: #2e3531;
    line-height: 20px;
    padding: 9px;
    margin-bottom: 4px;
  }

  .bottom-title {
    color: black;
    text-decoration: none;
    position: absolute;
    bottom: 41px;
    font-size: 12px;
    background-color: rgb(234, 235, 236);
    width: 100%;
    text-align: center;
    height: 25px;
    line-height: 25px;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
