import { render, staticRenderFns } from "./TalkView.vue?vue&type=template&id=57db4735&scoped=true"
import script from "./TalkView.vue?vue&type=script&lang=js"
export * from "./TalkView.vue?vue&type=script&lang=js"
import style0 from "./TalkView.vue?vue&type=style&index=0&id=57db4735&prod&scoped=true&lang=scss"
import style1 from "./TalkView.vue?vue&type=style&index=1&id=57db4735&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57db4735",
  null
  
)

export default component.exports