/**
 * 链接操作js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";
const request = axios.request;
/**
 * 获取快捷链接信息
 * @returns {Promise<unknown>}
 */
export const getquicklinks = (data) => {
  return request.doPost('/quicklinks/getquicklinks', data);
}

/**
 * 添加或者修改快捷链接信息
 * @returns {Promise<unknown>}
 */
export const addorupdatequicklinks = (data) => {
  return request.doPost('/quicklinks/addOrUpdatequicklinks', data);
}

/**
 * 删除快捷链接信息
 * @returns {Promise<unknown>}
 */
export const deletequicklinks = (data) => {
  return request.doPost('/quicklinks/deletequicklinks', data);
}

/**
 * 修改快捷链接状态
 * @returns {Promise<unknown>}
 */
export const updatequicklinksStatus = (data) => {
  return request.doPost('/quicklinks/updatequicklinksStatus', data);
}
/**
 * 获取快捷链接最大排序
 * @returns {Promise<unknown>}
 */
export const querymaxsortid = (data) => {
  return request.doPost('/quicklinks/querymaxsortid', data);
}

/**
 * 获取部分快捷链接
 * @returns {Promise<unknown>}
 */
export const getsomequicklinks = (data) => {
  return request.doPost('/quicklinks/getsomequicklinks', data);
}
